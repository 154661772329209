.taxi-location-manager {
	position: relative;

	display: grid;
	grid-template-columns: 45% 10% 45%;
	grid-template-rows: 100%;

	height: 100%;

	background-color: var(--bui_color_white);
	border-radius: var(--bui_border_radius_100);
}

.taxi-location-manager__input {
	position: relative;
	width: 100%;
}

.taxi-location-manager > .taxi-location-manager__input {
	place-self: center center;
}

.taxi-location-manager__swap-button {
	z-index: 1;
	height: 100%;
}

.taxi-location-manager__swap-button.bui-button--large {
	min-width: auto;
}

.taxi-location-manager__swap-button-icon {
	transform: rotate(90deg);
}

.taxi-location-manager__divider {
	margin-left: 36px;
}

.taxi-location-manager__route-line {
	position: absolute;
	z-index: 1;
	top: 36px;
	left: 18px;

	width: 1px;
	height: var(--bui_spacing_6x);

	background-color: var(--bui_color_foreground_disabled);
}

.taxi-location-manager--small-size.taxi-location-manager,
.taxi-location-manager--medium-size.taxi-location-manager {
	grid-template-columns: 90% 10%;
	grid-template-rows: 50% 50%;
	height: auto;
}

.taxi-location-manager--small-size .taxi-location-manager__input,
.taxi-location-manager--medium-size .taxi-location-manager__input {
	grid-column: 1;
}

.taxi-location-manager--small-size .taxi-location-manager__swap-button,
.taxi-location-manager--medium-size .taxi-location-manager__swap-button {
	grid-column: 2;
	grid-row: 1 / 3;
}

.taxi-location-manager--small-size .taxi-location-manager__swap-button-icon,
.taxi-location-manager--medium-size .taxi-location-manager__swap-button-icon {
	transform: rotate(0deg);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.taxi-location-manager--small-size.taxi-location-manager
		.taxi-location-manager__input.taxi-location-manager__input--pickup,
	.taxi-location-manager--medium-size.taxi-location-manager
		.taxi-location-manager__input.taxi-location-manager__input--pickup {
		grid-column: 1;
		grid-row: 1;
	}

	.taxi-location-manager--small-size.taxi-location-manager
		.taxi-location-manager__input.taxi-location-manager__input--dropoff,
	.taxi-location-manager--medium-size.taxi-location-manager
		.taxi-location-manager__input.taxi-location-manager__input--dropoff {
		grid-column: 1;
		grid-row: 2;
	}

	.taxi-location-manager--small-size.taxi-location-manager .taxi-location-manager__swap-button,
	.taxi-location-manager--medium-size.taxi-location-manager .taxi-location-manager__swap-button {
		grid-column: 2;
		grid-row: 1;
	}

	.taxi-location-manager--small-size.taxi-location-manager .taxi-location-manager__swap-button span.bui-button__icon,
	.taxi-location-manager--medium-size.taxi-location-manager .taxi-location-manager__swap-button span.bui-button__icon {
		margin: 0 -12px;
	}
}
