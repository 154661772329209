/* IE has issues with auto grid-column/grid-row, avoid using them or supply IE overrides */
.taxi-search-form {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.taxi-search-form__container {
	display: grid;
	grid-template-columns: 42% 18% 18% 1fr minmax(131px, 131px);
	grid-template-rows: auto;

	background-color: var(--bui_color_brand_genius_secondary_foreground);
	border: var(--bui_border_width_200) solid var(--bui_color_brand_genius_secondary_foreground);
	border-radius: var(--bui_border_radius_200);
}

.taxi-search-form__container_free_taxi {
	display: grid;
	grid-template-columns: 40% 40% 20%;
	grid-template-rows: auto;

	background-color: var(--bui_color_brand_genius_secondary_foreground);
	border: var(--bui_border_width_200) solid var(--bui_color_brand_genius_secondary_foreground);
	border-radius: var(--bui_border_radius_200);
}

.taxi-search-form__container_free_taxi_multi_iata {
	display: grid;
	grid-template-columns: 40% 20% 20% 20%;
	grid-template-rows: auto;

	background-color: var(--bui_color_brand_genius_secondary_foreground);
	border: var(--bui_border_width_200) solid var(--bui_color_brand_genius_secondary_foreground);
	border-radius: var(--bui_border_radius_200);
}

.taxi-search-form__container--hidden-passenger {
	grid-template-columns: 42% 18% 18% 1fr;
}

.taxi-search-form--single-search-type .taxi-search-form__container {
	grid-template-columns: 42% 18% 18% 1fr auto;
	grid-template-rows: auto;
}

.taxi-search-form--single-search-type--hidden-passenger .taxi-search-form__container {
	grid-template-columns: auto 18% 18%;
}

.taxi-search-form--single-search-type--hidden-passenger.taxi-search-form--medium-size .taxi-search-form__container {
	grid-template-columns: unset;
	grid-template-rows: unset;
}

.taxi-search-form--single-search-type--hidden-passenger.taxi-search-form--small-size .taxi-search-form__container {
	grid-template-columns: unset;
	grid-template-rows: unset;
}

.taxi-search-form__container-item {
	height: var(--taxi-search-form-field-height);
	border: var(--bui_border_width_200) solid var(--bui_color_brand_genius_secondary_foreground);
	border-radius: var(--bui_border_radius_200);
}

.taxi-search-form__container-item__button {
	height: 100%;
	padding-right: 0;
	padding-left: 0;
}

/* Default Container CSS */
/* TODO: should this be disabled */
/* stylelint-disable-next-line no-descending-specificity */
.taxi-search-form--small-size .taxi-search-form__container,
.taxi-search-form--medium-size .taxi-search-form__container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
}

/* Single Container CSS */
.taxi-search-form--small-size .taxi-search-form--single-search-type .taxi-search-form__container,
.taxi-search-form--medium-size .taxi-search-form--single-search-type .taxi-search-form__container {
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
}

/* Default Placement for Container Item */
.taxi-search-form--small-size .taxi-search-form__container-item,
.taxi-search-form--medium-size .taxi-search-form__container-item {
	grid-column: 1 / 3;
	height: auto;
}

.taxi-search-form--small-size .taxi-search-form__container-item.taxi-search-form__container-date-time:nth-child(2),
.taxi-search-form--medium-size .taxi-search-form__container-item.taxi-search-form__container-date-time:nth-child(2) {
	grid-column: 1;
	grid-row: 2;
}

.taxi-search-form--small-size .taxi-search-form__container-item.taxi-search-form__container-date-time:nth-child(3),
.taxi-search-form--medium-size .taxi-search-form__container-item.taxi-search-form__container-date-time:nth-child(3) {
	grid-column: 2;
	grid-row: 2;
}

.taxi-search-form--single-search-type.taxi-search-form--small-size .taxi-search-form__container-date-time,
.taxi-search-form--single-search-type.taxi-search-form--medium-size .taxi-search-form__container-date-time {
	grid-column: 1;
	grid-row: 2;
}

/* Hidden passengers field */
.taxi-search-form--single-search-type.taxi-search-form--small-size .taxi-search-form__container-date-time--full-width,
.taxi-search-form--single-search-type.taxi-search-form--medium-size .taxi-search-form__container-date-time--full-width {
	grid-row: 2;
}

/* End of DateTime Placement */

/* Passengers */
.taxi-search-form--small-size .taxi-search-form__container-taxi-passengers,
.taxi-search-form--medium-size .taxi-search-form__container-taxi-passengers {
	grid-row: 3;
}

.taxi-search-form--single-search-type.taxi-search-form--small-size .taxi-search-form__container-taxi-passengers,
.taxi-search-form--single-search-type.taxi-search-form--medium-size .taxi-search-form__container-taxi-passengers {
	grid-column: 2;
	grid-row: 2;
}

/* Button Placement */
.taxi-search-form--small-size .taxi-search-form__container-submit-button,
.taxi-search-form--medium-size .taxi-search-form__container-submit-button {
	grid-row: 4;
}

.taxi-search-form--single-search-type.taxi-search-form--small-size .taxi-search-form__container-submit-button,
.taxi-search-form--single-search-type.taxi-search-form--medium-size .taxi-search-form__container-submit-button {
	grid-row: 3;
}

.bui-input-text__side {
	flex-direction: unset;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.taxi-search-form--small-size .taxi-search-form__container-submit-button,
	.taxi-search-form--medium-size .taxi-search-form__container-submit-button {
		grid-column: 1;
	}

	.taxi-search-form--single-search-type.taxi-search-form--small-size .taxi-search-form__container-submit-button,
	.taxi-search-form--single-search-type.taxi-search-form--medium-size .taxi-search-form__container-submit-button {
		grid-column: 1;
	}

	.taxi-search-form--small-size
		.taxi-search-form__container-item.taxi-search-form__container-date-time
		.taxi-date-time-picker-form-element
		> button,
	.taxi-search-form--medium-size
		.taxi-search-form__container-item.taxi-search-form__container-date-time
		.taxi-date-time-picker-form-element
		> button {
		padding-top: var(--bui_spacing_4x);
		padding-bottom: var(--bui_spacing_4x);
	}
}

/* For stop zooming on mobile devices */
@media screen and (width <= 575px) {
	.taxi-search-form .bui-input-text__control,
	.taxi-search-form .bui-form__control,
	.taxi-search-form .bui-radio__label,
	.taxi-search-form .bui-button__text {
		font-size: var(--bui_spacing_4x);
	}
}

@media screen and (width <= 1023px) {
	.taxi-search-form__container_free_taxi,
	.taxi-search-form__container_free_taxi_multi_iata {
		grid-template-columns: 1fr;
	}
}
